import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import ShopItem from '../components/ShopItem'

export default class Shop extends React.Component {
  render() {
    const { data } = this.props
    const { edges: items } = data.allMarkdownRemark
    return (
      <div className="shop-container">
        <div className="shop-category">
          <h1>Print</h1>
        </div>
        <div className="shop">
          {items
            .filter(({ node }) => node.frontmatter.type === 'print')
            .map(({ node: item }) => (
              <ShopItem item={item} key={item.id} />
            ))}
        </div>
        <div className="shop-category">
          <h1>Merchandise</h1>
        </div>
        <div className="shop">
          {items
            .filter(({ node }) => node.frontmatter.type === 'merch')
            .map(({ node: item }) => (
              <ShopItem item={item} key={item.id} />
            ))}
        </div>
      </div>
    )
  }
}

Shop.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const shopQuery = graphql`
  query ShopQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___title], order: ASC }
      filter: { frontmatter: { templateKey: { eq: "shop-item" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 360, quality: 60) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            price
            soldOut
            description
            templateKey
            type
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
