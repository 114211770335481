import React from 'react';
import { Link } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

const ShopItem = props => {
  const { slug } = props.item.fields
  const { featuredImage, title, soldOut, price } = props.item.frontmatter
  return (
    <div className="shop__item">
      <Link className="shop__item__link link link--nav" to={slug}>
        <PreviewCompatibleImage
          imageClassName="shop__item__image"
          imageStyle={{
            position: 'relative'
          }}
          imageInfo={{
            image: featuredImage,
            alt: title
          }}
        />
        <div className="shop__item__title">
          <h4>{title}</h4>
        </div>
        <div className="shop__item__type">
          {soldOut
            ? 'Sold Out'
            : Number(price)
            ? `£${Number(price).toFixed(2)}`
            : price}
        </div>
      </Link>
    </div>
  )
}

export default ShopItem;
